<template>
  <!-- 被活动下抽奖列表引用的列表组件 -->
  <div class="question-wrap">
    <!-- <pageTitle pageTitle="奖品" v-if="$route.meta.title === '奖品'">
      <div slot="content">
        1.创建奖品不限制数量
        <br />
        2.奖品名称：不支持特殊符号、表情
        <br />
        3.奖品图片：尺寸240*240px，大小不超过2M
      </div>
    </pageTitle> -->
    <div class="head-operat" v-show="total || isSearch" :class="source == 0 ? 'head-prize' : ''">
      <vh-button size="medium" type="primary" round @click="handleClick">创建抽奖</vh-button>
      <!-- <vh-button size="white-medium" round class="transparent-btn" v-if="$route.meta.title !== '奖品'" @click="prizeMeterial">资料库</vh-button> -->
      <vh-button
        size="medium"
        round
        plain
        class="head-btn batch-del transparent-btn"
        @click="allDelete(null)"
        :disabled="!prizeChecked.length"
      >
        批量删除
      </vh-button>
      <vh-input
        round
        class="search-tag"
        placeholder="请输入抽奖名称"
        v-model="keyword"
        clearable
        v-clearEmoij
        @clear="searchTableList"
        @keyup.enter.native="searchTableList"
      >
        <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchTableList"></i>
      </vh-input>
    </div>
    <div class="no-live" v-if="!total && !isSearch">
      <noData :nullType="'nullData'" :text="'暂未创建抽奖'">
        <vh-button type="primary" round @click="handleClick" v-preventReClick>创建抽奖</vh-button>
      </noData>
    </div>
    <div class="question-list" v-show="total || isSearch">
      <!-- 有消息内容 -->
      <!-- 表格与分页 -->
      <vh-table
        ref="tableList"
        :data="tableData || []"
        @selection-change="changeTableCheckbox"
        max-height="504px"
        :header-cell-style="{
          background: '#f7f7f7',
          color: '#666',
          height: '56px'
        }"
      >
        <vh-table-column
          type="selection"
          width="60"
          fixed="left"
          align="left"
          :selectable="checkSelectable"
        />
        <vh-table-column
          align="left"
          v-for="(item, index) in tabelColumn"
          :key="index"
          :width="item.width"
          :label="item.label"
          :show-overflow-tooltip="true"
          :fixed="item.key == 'title' ? true : false"
        >
          <template slot-scope="scope">
            <span :class="item.key">
              <template v-if="item.key == 'has_winning_range'">
                {{ scope.row[item.key] ? '有' : '无' }}
              </template>
              <template v-else-if="item.key == 'lottery_status'">
                {{
                  scope.row[item.key] === 0
                    ? '抽奖中'
                    : scope.row[item.key] === 1
                    ? '已结束'
                    : '未推送'
                }}
              </template>
              <template v-else-if="item.key == 'winners_num'">
                <router-link
                  :to="{
                    path: `${isEmbed ? '/embed' : ''}/live/winnerList/${$route.params.str}`,
                    query: {
                      lotteryId: scope.row.lottery_id,
                      roomId: $route.query.roomId
                    }
                  }"
                >
                  {{ scope.row.winners_num }}
                </router-link>
              </template>
              <template v-else-if="item.key == 'lottery_method'">
                {{ scope.row[item.key] === 1 ? '倒计时开奖' : '手动开奖' }}
              </template>
              <template v-else>{{ scope.row[item.key] }}</template>
            </span>
          </template>
        </vh-table-column>
        <vh-table-column label="操作" fixed="right" align="left" width="180px">
          <template slot-scope="scope">
            <template v-for="(item, index) in tableRowBtnFun">
              <vh-button
                :key="index"
                type="text"
                v-preventReClick
                :disabled="item.abledKey && !scope.row[item.abledKey]"
                @click="onHandleBtnClick(item, scope.row)"
              >
                {{ item.name }}
              </vh-button>
            </template>
          </template>
        </vh-table-column>
      </vh-table>
      <SPagination
        :total="total || 0"
        :page-size="query.limit"
        :currentPage="query.pageNumber"
        @current-change="currentChangeHandler"
        @size-change="handleSizeChange"
      ></SPagination>
    </div>
    <create-prize
      ref="createPrize"
      @getTableList="getTableList"
      :prizeInfo="prizeInfo"
      :liveTotal="total"
      @touchmove.prevent
    ></create-prize>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import createPrize from '../LiveModule/MaterialSet/components/createPrize';
  import noData from '@/views/PlatformModule/Error/nullPage';
  import { sessionOrLocal, isEmbed, embedRoomId } from '@/utils/utils';
  export default {
    name: 'prize',
    props: {
      source: {
        type: String,
        required: false,
        default: '1'
      },
      roomId: {
        type: String,
        required: false,
        default: ''
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        total: 0,
        isSearch: false,
        keyword: '',
        userId: JSON.parse(sessionOrLocal.get('userId')),
        prizeInfo: {},
        isDelete: false,
        searchAreaLayout: [
          {
            key: 'keyword'
          }
        ],
        tabelColumn: [
          // {
          //   label: '奖品id',
          //   key: 'prize_id',
          // },
          {
            label: '抽奖标题',
            key: 'title',
            width: 200
          },
          {
            label: '奖品数量',
            key: 'quantity',
            width: 100
          },
          {
            label: '参与条件',
            key: 'lottery_type_name',
            width: 120
          },
          {
            label: '设定中奖范围',
            key: 'has_winning_range',
            width: 120
          },
          {
            label: '中奖人数',
            key: 'winners_num',
            width: 100
          },
          {
            label: '开奖方式',
            key: 'lottery_method',
            width: 120
          },
          {
            label: '状态',
            key: 'lottery_status'
          }
        ],
        tableRowBtnFun: [
          { name: '复制', methodName: 'copy' },
          { name: '编辑', methodName: 'edit', abledKey: 'isCanEedit' },
          { name: '删除', methodName: 'del', abledKey: 'lottery_status' }
        ],
        prizeChecked: [],
        tableData: [],
        query: {
          pos: 0,
          limit: 20,
          pageNumber: 1
        }
      };
    },
    components: {
      PageTitle,
      createPrize,
      noData
    },
    mounted() {
      this.getTableList();
      console.log(this.$route);
    },
    methods: {
      onHandleBtnClick(info, val) {
        this[info.methodName](val);
      },
      handleClick() {
        // this.activeName = tab.name;
        this.$router.push({
          path: `${isEmbed() ? '/embed' : ''}/live/prizeSet/${this.$route.params.str}`,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId
          }
        });
      },
      searchTableList() {
        if (this.keyword) {
          this.$vhall_paas_port({
            k: this.$route.params.str ? 100332 : 100539,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str || '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getTableList('search');
      },
      getTableList(params) {
        //获取分页信息
        let formParams = {
          title: this.keyword,
          webinar_id: this.$route.params.str || ''
        }; //获取搜索参数
        if (params == 'search') {
          this.query.pageNumber = 1;
          this.query.pos = 0;
        }
        if (this.source == '0') {
          formParams.room_id = this.roomId;
        }
        this.isSearch = this.keyword ? true : false;
        formParams.source = this.source;
        let obj = Object.assign({}, this.query, formParams);

        this.$fetch('getLotteryList', obj).then(res => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.tableData.forEach(item => {
            item.isCanEedit = item.lottery_status == 2;
          });
          // this.tableData.map(item => {
          //   item.img = item.img_path;
          // })
        });
      },
      // 复制
      copy(rows) {
        let params = {
          lottery_id: rows.lottery_id,
          webinar_id: this.$route.params.str || ''
        };
        this.$fetch('copyLottery', this.$params(params)).then(res => {
          this.$vhall_paas_port({
            k: this.$route.params.str ? 100327 : 100536,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str || '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.$vhMessage.success('复制成功');
          this.getTableList();
        });
      },
      // 编辑
      edit(rows) {
        console.log('------bianji----');
        this.$router.push({
          path: `${isEmbed() ? '/embed' : ''}/live/prizeSet/${this.$route.params.str}`,
          query: {
            roomId: embedRoomId() || this.$route.query.roomId,
            lotteryId: rows.lottery_id
          }
        });
      },
      // 删除
      del(rows) {
        this.deleteConfirm(rows.lottery_id, 2);
      },
      deleteConfirm(id, index) {
        this.$vhConfirm('删除后，将影响直播间的使用同时数据将被删除，是否删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          cancelButtonClass: 'zdy-confirm-cancel',

          roundButton: true
        })
          .then(() => {
            console.log('-----------', id);
            let params = {
              lottery_ids: id,
              webinar_id: this.$route.params.str
            };
            this.$fetch('delLottery', this.$params(params))
              .then(res => {
                if (res.code == 200) {
                  this.getTableList('search');
                  let k = 0;
                  if (this.$route.params.str) {
                    k = index === 1 ? 100329 : 100328;
                  } else {
                    k = index === 1 ? 100538 : 100537;
                  }
                  this.$vhall_paas_port({
                    k: k,
                    data: {
                      business_uid: this.userId,
                      user_id: '',
                      webinar_id: this.$route.params.str || '',
                      refer: '',
                      s: '',
                      report_extra: {},
                      ref_url: '',
                      req_url: ''
                    }
                  });
                  this.$vhMessage({
                    message: `删除成功`,
                    showClose: true,
                    // duration: 0,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                }
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `删除失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          })
          .catch(() => {
            this.$vhMessage({
              message: `已取消删除`,
              showClose: true,
              type: 'info',
              customClass: 'zdy-info-box'
            });
          });
      },
      allDelete(id) {
        id = this.prizeChecked.join(',');
        this.deleteConfirm(id, 1);
      },
      // 选中
      changeTableCheckbox(val) {
        this.prizeChecked = val.map(item => item.lottery_id);
      },
      // 创建奖品
      createPrize() {
        if (this.source == 0 && Number(this.total) >= 20) {
          this.$vhMessage({
            message: `每个活动最多显示20个奖品，超过20个后无法关联，需要将原有奖品删除`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (this.tableData.length) {
          this.$refs.tableList.clearSelect();
        }
        this.prizeInfo = {};
        this.$refs.createPrize.dialogVisible = true;
      },
      // 从资料库中选择
      prizeMeterial() {
        this.$refs.createPrize.dialogPrizeVisible = true;
        // document.documentElement.style.overflow = "hidden";
        // document.documentElement.style.position = "fixed";
        // document.body.scroll = "no";
      },
      checkSelectable(row) {
        return Number(row.lottery_status) != 0;
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.query.pageNumber = current;
        this.query.pos = parseInt((current - 1) * this.query.limit);
        this.getTableList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.query.pageNumber = 1;
        this.query.limit = current;
        this.query.pos = 0;
        this.getTableList();
      }
    }
  };
</script>

<style lang="less" scoped>
  .question-list {
    width: 100%;
    margin-top: 24px;
    .layout--right--main();
    .padding-table-list();
  }
  .question-wrap {
    height: 100%;
    width: 100%;
    .head-operat {
      margin-bottom: 20px;
      .head-btn {
        display: inline-block;
      }
      ::v-deep .set-upload {
        position: relative;
        span {
          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      /* .inputKey{
      float: right;
      height: 35px;
      width: 220px;
      /deep/.vh-input__inner{
        border-radius: 18px;
      }
    } */
      .search-tag {
        float: right;
        width: 220px;
        &.vh-input {
          ::v-deep .vh-input__inner {
            height: 36px;
            line-height: 36px;
          }
          ::v-deep .vh-input__icon {
            line-height: 36px;
          }
        }
      }
    }
    // .head-prize {
    //   margin: 0 24px;
    // }
    .prize-no {
      // height: calc(100vh - 260px);
      text-align: center;
      margin-top: 208px;
    }
    .no-live {
      padding-bottom: 150px;
    }
  }
</style>

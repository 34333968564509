<template>
  <!-- 活动下抽奖列表 -->
  <div class="prize-card">
    <pageTitle pageTitle="抽奖">
      <span class="notice">
        同一手机号/邮箱通过不同终端参会可能会出现多条中奖，建议联系售后人员配置用户的单点参会功能
      </span>
    </pageTitle>
    <div class="prize-info">
      <prize-list :source="'0'" :roomId="roomId"></prize-list>
    </div>
    <begin-play :webinarId="$route.params.str"></begin-play>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import prizeList from '../../MaterialModule/prizeList';
  import beginPlay from '@/components/beginBtn';
  import { embedRoomId } from '../../../utils/utils';

  export default {
    name: 'prizeSet',
    data() {
      return {
        roomId: embedRoomId() || this.$route.query.roomId
      };
    },
    components: {
      PageTitle,
      beginPlay,
      prizeList
    }
  };
</script>

<style lang="less" scoped>
  .prize-card {
    height: 100%;
    .notice {
      color: #999;
      font-size: 14px;
    }
    .card__body {
      padding: 0;
      background: #fff;
      // max-width: 1374px;
      margin: auto;
      border-radius: 4px;
    }
    /deep/.vh-switch {
      float: right;
      padding-top: 10px;
      margin-right: 0;
      // /deep/.vh-switch__core {
      //   width: 32px!important;
      //   height: 20px;
      // }
      // /deep/.vh-switch__core::after {
      //   top: 2px;
      //   width: 14px;
      //   height: 14px;
      // }
    }
    /deep/.vh-form-item__label {
      color: #1a1a1a;
    }
    /deep/.vh-switch__label.is-active {
      color: #1a1a1a;
    }
    /deep/.vh-tabs__content {
      min-height: 700px;
    }
    .prize-info {
      margin: 32px 0;
      // .question-list{
      //   padding: 24px 0;
      // }
    }
    .prize-list {
      padding: 33px 24px;
      .head-operat {
        margin-bottom: 30px;
      }
    }
    .give-item {
      padding: 40px 24px;
      display: flex;
      /deep/.vh-form {
        position: relative;
      }
      /deep/.vh-input__inner {
        height: 40px;
      }
      /deep/.vh-input {
        input {
          padding: 0 12px;
        }
        input::-webkit-input-placeholder {
          color: #999;
        }
      }
      .give-prize {
        width: 480px;
        margin-right: 80px;
        .isDelete {
          float: right;
          height: 20px;
          i {
            font-size: 16px;
            vertical-align: top;
            line-height: 32px;
            cursor: pointer;
          }
        }
        .switch__box {
          display: inline-block;
          padding-left: 5px;
          /deep/.vh-switch {
            padding-top: 0px;
          }
        }
        /deep/.vh-form-item__label {
          height: 40px;
          width: 100px;
          overflow: hidden;
          // text-overflow:ellipsis;
          // white-space: nowrap;
        }
        /deep/.vh-button {
          margin-top: 25px;
        }
        &-previewImg {
          width: 240px;
          height: 240px;
        }
      }
      .add-prize {
        position: absolute;
        left: 100px;
        bottom: 85px;
        font-size: 14px;
        color: #3562fa;
        cursor: pointer;
        i {
          color: #3562fa;
          // border: 1px dashed #3562FA;
        }
        .vh-icon-plus:before {
          font-weight: bolder;
        }
      }
      .give-show {
        width: 370px;
        height: 631px;
        background-image: url('../../../common/images/gif/prize.png');
        background-size: cover;
        margin-top: -25px;
        position: relative;
        .give-people {
          .accept-review {
            margin: auto;
            margin-top: 227px;
            width: 276px;
            height: 279px;
            margin-left: 0;
            background-image: url('../../../common/images/gif/accept-review.png');
            background-size: 100%;
            border-radius: 0 0 24px 24px;
          }

          h3 {
            text-align: center;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            color: #1a1a1a;
            position: relative;
            i {
              position: absolute;
              top: 12px;
              right: 12px;
              cursor: pointer;
            }
          }
          .sureBtn {
            width: 180px;
            margin: auto;
            text-align: center;
            color: #fb3a32;
          }
          .padding-container {
            padding-top: 45px;
          }
          ::-webkit-scrollbar {
            display: none;
          }
          .give-msg {
            text-align: center;
            margin: 10px auto;
            height: 160px;
            padding: 0 16px;
            overflow-y: auto;

            p {
              margin-top: 24px;
            }
            .red {
              position: absolute;
              left: 0px;
              top: 2px;
              display: inline-block;
              color: #fe6a6a;
              margin-right: 10px;
            }
          }
          .form-item {
            padding-left: 15px;
            background: #fff;
            border-radius: 4px;
            margin-bottom: 9px;
            /deep/ input {
              border: 0;
              color: #bfbfbf;
            }
          }
        }
        .prize-mask {
          position: absolute;
          top: 85px;
          left: 50px;
          width: 276px;
          height: 505px;
          background: rgba(#000, 0.7);
          border-radius: 0 0 24px 24px;
        }
        .prize-show {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0 auto;
          text-align: center;
          width: 200px;
          height: 230px;
          background-size: 100%;
          background-repeat: no-repeat;
          margin-top: 16px;
          background-position: center;
        }
      }
      .prize-type {
        display: flex;
        text-align: center;
        justify-content: space-between;
        .przieImg {
          width: 88px;
          height: 88px;
          border-radius: 4px;
          border: 1px solid #ccc;
          position: relative;
          transition: all 0.15s ease-in;
          &:hover {
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          }
          &.active {
            border: 1px solid #fb3a32;
            box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
          }
          img {
            width: 100%;
            height: 100%;
          }
          .img-tangle {
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 20px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .prizeNoBorder {
          border: 0;
          /deep/.vh-upload--picture-card {
            height: 88px;
          }
          /deep/.vh-upload--picture-card .box > div {
            height: 88px;
          }
        }
        .checkedBorder {
          border: 1px solid #fb3a32 !important;
          /deep/.vh-upload--picture-card {
            border: 0;
            height: 86px;
          }
        }
      }
    }
    .prize-no {
      height: calc(100vh - 260px);
      text-align: center;
      margin-top: 268px;
    }
  }
  /deep/.saasicon_help_m {
    color: #999999;
  }
</style>
